import React, {Component} from 'react';
import logo from '../images/logo.svg'
import { Link } from 'gatsby'
import discord from '../images/discord.png'

class Header extends Component {

    constructor(props) {
        super(props);

        this.state = {
            open:false,
            // windowWidth: window.innerWidth,
        };
        this.nav = React.createRef();
        this.logo = React.createRef();

    }

    navToggle() {
        var btn = document.getElementById('menuBtn');
        var nav = document.getElementById('menu');
        var header = document.getElementById('site-menu');
        var menuList = document.getElementById('menu-item-li');

        btn.classList.toggle('open');
        nav.classList.toggle('flex');
        nav.classList.toggle('hidden');
        header.classList.toggle('header-color');
        menuList.classList.remove('hidden');


    }



      componentDidMount() {

        let nav = this.nav.current;
        let logo = this.logo.current;

        window.addEventListener('scroll', function() {
         if (window.scrollY >=150) { // adjust this value based on site structure and header image height
            nav.classList.add('nav-sticky');
            logo.classList.add('logo-sticky');

         } else {
            nav.classList.remove('nav-sticky');
            logo.classList.remove('logo-sticky');

    }
            });



    }

    render() {


        return (
     <header id="top" ref={this.nav} className="mr-auto ml-auto w-full flex flex-col fixed pin-t pin-r pin-l bg-gray-900 md:bg-transparent">
          <nav ref={this.nav} id="site-menu"
               className="header flex flex-col lg:flex-row w-full justify-between px-4 lg:pt-2  py-1 bg-gray-800 lg:shadow-none lg:bg-transparent">
              <div
                  className="w-full lg:w-2/12 lg:w-auto self-start lg:self-center flex flex-row lg:flex-none flex-no-wrap justify-between items-center">
                  <Link to={"/"} className="">
                      <img id="logo" ref={this.logo} className="" src={logo}/>
                  </Link>
                  <button id="menuBtn" ref={this.menuBtn} className="hamburger block lg:hidden focus:outline-none" type="button"
                          onClick = {this.navToggle}>
                      <span className="hamburger__top-bun"/>
                      <span className="hamburger__bottom-bun"/>
                  </button>
              </div>
              <div id="menu"
                   className="text-gray-100 font-medium pt-6 lg:pt-0 lg:w-11/12 w-full lg:w-auto self-end lg:self-center lg:flex flex-col lg:flex-row h-full py-1 pb-4 lg:py-0 lg:pb-0 hidden md:justify-end">
                  <Link className="flex justify-center text-2xl md:text-base  w-full no-underline lg:w-auto lg:pr-4 py-2 lg:py-1 "
                     to={"/articles/"}>Articles</Link>
                  <Link className="flex justify-center md:text-base text-2xl w-full no-underline lg:w-auto lg:pr-4 py-2 lg:py-1 "
                     to={"/about/"} >About</Link>
                  <div className="flex justify-center">
                  <Link to={"/subscription/"} id="menu-item-li" className="text-2xl md:hidden hover:bg-gray-800  border-solid border-pink-700 border-2  px-2 pt-0 text-sm rounded">
                  Subscribe</Link></div>



              </div>
              <div className="lg:w-1/12 w-full hidden lg:flex items-center flex content-center flex-wrap text-gray-100 mr-4">
                  <Link to={"/subscription/"} id="menu-item-li" className="">
                  Subscribe</Link>
              </div>

              {/*<div className="lg:w-1/12 w-full hidden lg:flex items-center flex content-center flex-wrap text-gray-100">*/}
              {/*    <a href="https://discord.gg/37xQKMUDP9" id="menu-item-li" className="">*/}
              {/*    <img className=" w-64" src={discord}/></a>*/}
              {/*</div>*/}
          </nav>
      </header>
  );
    }


}

export default Header;
