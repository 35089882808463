/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from 'gatsby'

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header  siteTitle={data.site.siteMetadata?.title || `Title`} />

        <main className="pt-0 bg-gray-900">{children}</main>
        <footer className="bg-gray-900 text-gray-300 pb-4 mt-8">
        <section>
          <div id="footer" className="container mx-auto px-6 flex justify-end text-sm">
            <span className="mr-8"><a href="mailto:hello@balticmakers.com">hello@balticmakers.com</a></span>
            <span className="">© {new Date().getFullYear()} Baltic Makers</span>
            {/*<span className="ml-4"><Link to={"/about"}>Cookie Policy</Link> </span>*/}
          </div>

        </section>


        </footer>

    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
